<template>
  <div class="auth-wrapper auth-v1" >
    <div class="auth-inner">
      <v-card class="auth-card"  @click="llamarMesonero()" :class=" aceptado == true ? 'primary' : '' ">
        <!-- logo -->

        <!-- title -->

        <!-- login form -->
        <v-card-text class="text-center py-6 my-6" >

      
          <span v-if="starTime">
            <v-progress-circular
              :rotate="360"
              :size="100"
              :width="50"
              :value="(time / 30) * 100"
              color="primary"
            ></v-progress-circular>
            <h1>LLAMANDO...</h1>
            <h3>Tiempo {{ time }}</h3>
          </span>
          <span v-else-if="aceptado == false">
            <v-icon size="200" color="primary"> {{ icons.mdiAccountTie }}</v-icon>
            <h1>LLAMAR A UN MESONERO.</h1>
          </span>
          <span v-else>
           

            <v-avatar size="150">
                  <v-img
                    :src="`${confix}/empleado/img/${datos.datos.empleado.img}`"
                    :lazy-src="`${confix}/empleado/img/empleado_default.jpg`"
                    height="150px"
                  >
                  </v-img>
                </v-avatar>
         
            <h1>YA LO ATENDEREMOS</h1>
          </span>
          <span v-if="aceptado">
            <p class="my-0 py-0">Sera atendido por</p>
            <h3>{{ datos.datos.empleado.nombre }} {{ datos.datos.empleado.apellido }}</h3>
          </span>
        </v-card-text>

        <!-- create new account  -->

        <!-- divider -->
      </v-card>

      <v-card class="auth-card mt-2" @click="dialogMesa=true" >
        <v-card-text class="text-center" v-if="mesaSelect.id">
          <h3>{{mesaSelect.grupo_mesa}}</h3>
          <p>{{mesaSelect.nombre}}</p>
        </v-card-text>  
        <v-card-text class="text-center" v-else>
          <h3>DEBE SELECCIONAR UNA MESA</h3>
         
        </v-card-text>  
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="200"
      contain
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <!-- tree -->
    <v-img class="auth-tree" width="247" height="185" src="@/assets/images/misc/tree.png"></v-img>

    <!-- tree  -->
    <v-img class="auth-tree-3" width="377" height="289" src="@/assets/images/misc/tree-3.png"></v-img>
    <modal-mesas
      :label="''"
      @GetMesa="GetMesa"
      v-model="dialogMesa"
      :verBtn="false"
    ></modal-mesas>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiCheck,
  mdiFacebook,
  mdiTwitter,
  mdiGithub,
  mdiGoogle,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiAccountTie,
} from '@mdi/js'
import { onBeforeMount, ref } from '@vue/composition-api'
import { socket, state as stateSocket } from '@/plugins/sockets'
import funciones from '@/funciones/funciones'
import ModalMesas from '../sistema/comanda/dashboard/componentes/ModalMesas.vue'
import store from '@/store'
export default {
  components:{
    ModalMesas
  },
    mounted()  {
   
    this.$vuetify.theme.dark = true
  },
  setup() {
    socket.on('alert-confirmado', args => {
      starTime.value = false
      aceptado.value = true
      datos.value = args
      console.log(args)
      setTimeout(()=>{
        if (starTime.value ==false && aceptado.value == true) {
        
          limpiar()
        }
      },10000)
    })
    const confix = config.VUE_APP_URL_IMG
    const isPasswordVisible = ref(false)
    const username = ref('')
    const email = ref('')
    const password = ref('')
    const time = ref(0)
    const aceptado = ref(false)
    const datos = ref({})
    const starTime = ref(false)
    const dialogMesa = ref(false)
    const limpiar = () => {
      time.value = 0
      aceptado.value = false
      datos.value = {}
      starTime.value = false
    }
    const GetMesa=(items)=>{
      mesaSelect.value = items

      store.commit('setLlamarMesoneroMesa',mesaSelect.value)
      console.log(items)
    }

    onBeforeMount(()=>{
       mesaSelect.value = store.state.llamarMesoneroMesa
    })

    const mesaSelect   = ref({})
    const llamarMesonero = () => {
      if (mesaSelect.id == undefined) {
      if (starTime.value == false) {
        socket.emit('alerta', {
          id_usuario_receptor: 0,
          grupo:mesaSelect.value.grupo_mesa,
          sub_titulo: 'SE NECESITA UN MESONERO',
          color: 'warning',
          idTran: funciones.getRanHex(12),
          empleado: {
            id: -1,
            nombre: '',
            nombre_empleado: mesaSelect.value.grupo_mesa,
            apellido_empleado: mesaSelect.value.nombre,
          },
        })

        time.value = 30
        starTime.value = true
        aceptado.value = false
        descontarTime()
      }
    }else{
      store.commit('setAlert', {
          message: 'Debe seleccionar una mesa',
          type: 'waring',
          
        })
    }
    }

    const descontarTime = () => {
      if (time.value > 0) {
        if (starTime.value == true) {
          setTimeout(() => {
            time.value = time.value - 1
            descontarTime()
          }, 1000)
        }
      } else {
        if (starTime.value == true) {
          starTime.value = false
        }
      }
    }

    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    return {
      isPasswordVisible,
      username,
      email,
      password,
      socialLink,
      llamarMesonero,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiAccountTie,
        mdiCheck,
      },
      starTime,
      time,
      aceptado,
      datos,
      confix,
      dialogMesa,
      GetMesa,
      mesaSelect
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
